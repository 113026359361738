.changePasswordContainer {
  form {
    label {
      margin: 5px 0;
    }
  }
}

.swalModal {
  opacity: 0.92 !important;
  width: calc(100% - 80px);
  height: calc(50% - 130px);
}
