@font-face {
  font-family: 'League Gothic';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/LeagueGothic/League-Gothic-Regular.eot'); /* IE9 Compat Modes */
  src: local('League Gothic'), local('LeagueGothic'),
    url('../../assets/fonts/LeagueGothic/League-Gothic-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../../assets/fonts/LeagueGothic/League-Gothic-Regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../assets/fonts/LeagueGothic/League-Gothic-Regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../assets/fonts/LeagueGothic/League-Gothic-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../assets/fonts/LeagueGothic/League-Gothic-Regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

a,
a:hover {
  text-decoration: none;
}
.header {
  padding: 15px;
  display: flex;
  align-items: center;
  background: #555555;
  span {
    color: #fff;
    text-decoration: none;
  }

  img {
    max-width: 55px;
    border-radius: 100%;
    margin-right: 15px;
  }
}

.slider {
  width: 50%;
  margin: 0 auto;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: #fff;
  padding: 30px 10px 0 10px;
  h3,
  h4 {
    font-family: 'League Gothic';
    font-weight: normal;
    width: 100%;
    text-align: center;
    margin: 0;
  }
  p {
    width: 100%;
    text-align: center;
  }
}

.nav {
  padding: 30px 0px;
  list-style: none;
  max-width: 100%;
  margin: 0 auto;
  li {
    display: block;
  }

  a {
    padding: 20px;
    color: #fff;
    display: block;
    font-size: 1.5em;
    display: flex;
    justify-content: space-between;
  }
}
