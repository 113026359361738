  @import url('https://fonts.googleapis.com/css?family=Oswald|Nunito+Sans&display=swap');

  /** For devices above a certain size, always display a vertical phone layout */
  /* @media only screen and (min-height: 600px) and (orientation: landscape) {
    * {
      max-width: 600px;
    }
  } */

  html {
    background-color: black;
  }

  .signup {
    text-align: center;
  }
  
  .table { overflow: hidden; }
  body { margin-top: 0; }

  .spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .spinner {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .started .localVideo {
    width: 20%;
    position: absolute;
    z-index: 2;
    bottom: 10%;
    left: 2%;
    display: block;
    border-radius: 3px;
    border: 2px solid white;
  }
  .started .remoteVideos {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .started .remoteVideos video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* object-fit: contain; */
  }
  .not-started .localVideo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .not-started .remoteVideos {
    display: none;
  }
  .not-started .remoteVideos video {
    display: none;
  }
  .videosContainer {
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .videosContainer .info {
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    position: fixed;
    height: 9%;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user-img-wrapper {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
    height: 0;
  }
  .user-img-wrapper > img {
    position: absolute;
    width: 100%;
  }
  .form-control.has-error {
      border-color: red;
  }
  .invalid-feedback {
      padding: 5px 0;
      color: red;
  }

  .floating-label {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding-top: 5px;
    position: relative;
  }
    
  .floating-label + .floating-label {
    margin-top: 0.5em;
  }
    
  .floating-label input,
  .floating-label textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid white;
    box-sizing: border-box;
    font-size: 2rem;
    padding: 12px 0 8px 0;
    color: white;
    background: rgba(0,0,0,0);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: normal;
  }
    
  .floating-label input:focus,
  .floating-label textarea:focus {
    outline: none;
  }
    
  .floating-label input:focus + span,
  .floating-label textarea:focus + span,
  .floating-label.floating span {
    font-size: 1rem;
    padding: 0;
    color: rgba(255,255,255,0.5);
  }

  .floating-label input[value=""]:focus + span,
  .floating-label textarea[value=""]:focus + span {
    font-size: 1.25rem;
    padding: 22px 0 5px 12px;
    color: white;
  }
    
  .floating-label input:focus:not(:focus-visible),
  .floating-label textarea:focus:not(:focus-visible) {
    outline: none;
  }
    
  .floating-label span {
    box-sizing: border-box;
    font-size: 1.25rem;
    left: 0;
    padding: 22px 0 5px 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: font-size 2ms, padding 200ms;
    z-index: 1;
    color: white;
  }
  