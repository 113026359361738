.description {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.name {
  display: block;
  font-weight: bold;
}

.count {
  display: block;
  color: #ccc;
  font-style: normal;
}
