.userName {
  display: flex;
  overflow: hidden;
  align-items: center;
  margin-top: 2%;
  text-overflow: ellipsis;

  > i {
    margin-left: auto;
    font-size: 18px;
    line-height: 1;
  }
}
