.bar {
  padding: 10px;
  background: #222;
}

.barNavigation {
  display: flex;
}

.userIconWrapper {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 41px;
}

.userIcon {
  position: absolute;
  top: calc(50% - 0px);
  left: calc(50% - 5px);
  display: block;
  color: white;
}

.interestsIconWrapper {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 41px;
}

.interestsIcon {
  position: absolute;
  top: calc(50% - 0px);
  left: calc(50% - 5px);
  display: block;
  color: white;
}

.followersIconWrapper {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 41px;
}

.followersIcon {
  position: absolute;
  top: calc(50% - 0px);
  left: calc(50% - 5px);
  display: block;
  color: white;
}

.crownIconWrapper {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 41px;
}

.crownIcon {
  position: absolute;
  top: calc(50% - 0px);
  left: calc(50% - 5px);
  display: block;
  color: white;
}
