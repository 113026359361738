.container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  background: black;
}

.video {
  position: fixed;
  object-fit: cover;
  z-index: 0;
  width: 100% !important;
  height: 100% !important;
}

.actions {
  position: fixed;
  bottom: 0;
  z-index: 100;
  background: #fff;
  width: 100%;
  display: flex;
}

.link {
  flex: 1;
  text-align: center;
  padding: 15px;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
}

.linkDark {
  background: #222;
  color: #fff;
}
