.notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: white;
  padding: 20px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.5);
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-name: fadeOut;
}

.incomingCall {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(20,20,20,0.9);
  padding: 20px;
}

.notification span {
  font-size: 1.3em;
}

@-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  90% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  90% {opacity: 1;}
  100% {opacity: 0;}
}
