@font-face {
  font-family: 'League Gothic';
  font-style: normal;
  font-weight: 400;
  src: url('../../../../assets/fonts/LeagueGothic/League-Gothic-Regular.eot'); /* IE9 Compat Modes */
  src: local('League Gothic'), local('LeagueGothic'),
    url('../../../../assets/fonts/LeagueGothic/League-Gothic-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('../../../../assets/fonts/LeagueGothic/League-Gothic-Regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../../../../assets/fonts/LeagueGothic/League-Gothic-Regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../../../assets/fonts/LeagueGothic/League-Gothic-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../../../assets/fonts/LeagueGothic/League-Gothic-Regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
