.buttonListItem {
  margin-bottom: 15px;
  position: relative;
}

.buttonListItemButton {
  text-align: center;
  text-transform: uppercase;
  background: #00aedc;
  color: #fff !important;
  border-radius: 30px;
  padding: 13px 18px;
}

.buttonListItemButtonActive {
  background: #24d36b;
  color: #fff;
}

.buttonListItemIcon {
  margin-right: 10px;
  padding: 12px;
  background: #027998;
  border-radius: 100%;
  display: inline-block;
  width: 38px;
  height: 38px;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 14px;
  top: 5px;

  .buttonListItemButtonActive & {
    background: #0f8f42;
  }
}
