.swalModal {
  opacity: 0.92 !important;
  width: calc(100% - 80px);
  height: calc(50% - 60px);
}

.swalModalTwo {
  opacity: 0.92 !important;
  width: calc(100% - 80px);
  height: calc(50% - 130px);
}
