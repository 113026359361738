.buttonListItem {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.buttonListItemButton {
  text-align: left;
  text-transform: uppercase;
  background: #337ab7;
  color: #fff !important;
}

.buttonListItemButtonActive {
  background: green;
  color: #fff;
}

.buttonListItemIcon {
  margin-right: 10px;
  padding: 15px;
  background: darken(#337ab7, 15%);
  border-radius: 100%;
  display: inline-block;
  width: 50px;
  text-align: center;
  color: #fff;

  .buttonListItemButtonActive & {
    background: darken(green, 15%);
  }
}
