.genderIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50%);
  height: 100%;
  border: 1px solid #fff;
  border-radius: 100%;
}
.genderIconWrapper:first-of-type {
  margin-right: 10px;
}
.genderIconWrapperActive {
  border-color: #00b0df;
  background: #00b0df;
}

.genderIcon {
  color: #fff;
}

.genderIconActive {
  color: #fff;
}
